// Importing JavaScript files
import './js/jquery-3.6.0.min.js';
import './js/bootstrap.js';

// Importing SCSS files
import './styles/bootstrap.scss';
import './styles/index.scss';
import './styles/w3.scss';

// Importing index view
import home from './views/index.ejs';

// Importing components
import navbar from './components/navbar.ejs';

// Importing Services View
import services from './views/services.ejs';

// Importing Projects View
import projects from './views/projects.ejs'; 

// Importing About View
import about from './views/about.ejs';

// Importing Contact View
import contact from './views/contact.ejs';

// Importing Login View
import login from './views/login.ejs';

//Importing Register View
import register from './views/register.ejs';

// Importing assets
import logo_ubn from './assets/logo_urbiznow.png';
import content_img from './assets/content-image.jpg';
import logo_et from './assets/logo_eotsr.png';
import SSpace from './assets/static_space.png';
import projects_img from './assets/project.png';
import hhnt2 from './assets/HHNT2.png';
import hhntwtshirt from './assets/HHNTWTShirt.png';
import hhnt0 from './assets/HHNT0.png';
import hhnt from './assets/HHNT.png';
import zoa from './assets/zone6banner.jpg';
import mobmedic from './assets/mobile_medic.png';
import memloss from './assets/ML_Image.png';
import wah  from './assets/wah.png';




// Setting navbar
const navbar_content = document.getElementById('navbar-content');
if (navbar_content) navbar_content.innerHTML = navbar();

// Setting content in the middle
const middleContent = document.getElementById('middle-content');
if (middleContent) middleContent.innerHTML = home(); // Add home view to middle-content

// Setting Image of content text
const word_content = document.getElementById('word_content');
if (word_content) word_content.src = content_img;




document.addEventListener('DOMContentLoaded', function() {

  // Setting Home Page
  const homeBtn = document.getElementById('home_btn');
  if (homeBtn) {
    homeBtn.addEventListener('click', () => {
        // Refresh the page
        location.reload();
    });
  }

  // Setting Services Page
  const servicesBtn = document.getElementById('services_btn');
  if (servicesBtn) {
    servicesBtn.addEventListener('click', () => {
      if (middleContent) middleContent.innerHTML = services();
    });
  }

  // Setting Projects Page
  const projectsBtn = document.getElementById('projects_btn');
  if (projectsBtn) {
    projectsBtn.addEventListener('click', () => {
      if (middleContent) middleContent.innerHTML = projects();
    });
  }

  // Setting About Page
  const aboutBtn = document.getElementById('about_btn');
  if (aboutBtn) {
    aboutBtn.addEventListener('click', () => {
      if (middleContent) middleContent.innerHTML = about();
    });
  }

  // Setting Contact Page
  const contactBtn = document.getElementById('contact_btn');
  if (contactBtn) {
    contactBtn.addEventListener('click', () => {
      if (middleContent) middleContent.innerHTML = contact();
    });
  }

  // Setting Login Page
const loginBtn = document.getElementById('login_btn');
if (loginBtn) {
  loginBtn.addEventListener('click', () => {
    if (middleContent) middleContent.innerHTML = login();
  });
   
}

//Setting Register Page
const registerBtn = document.getElementById('register_btn');
if (registerBtn) {
  registerBtn.addEventListener('click', () => {
    if (middleContent) middleContent.innerHTML = register();
  });
}

});